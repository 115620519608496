<template>
    <div class="blogeditor">
        <h1 v-show="!editState">小程序文章发布</h1>
        <h1 v-show="editState">小程序文章修改</h1>
        <el-input class="info" v-model="blog.title" placeholder="标题"></el-input>
        <!-- <el-input class="info" v-model="blog.target" placeholder="标签"></el-input> -->
        <el-input class="info" v-model="blog.coverPic" placeholder="封面链接"></el-input>
        <div class="info">
            <el-radio v-model="blog.target" label="1">七上</el-radio>
        <el-radio v-model="blog.target" label="2">七下</el-radio>
        <el-radio v-model="blog.target" label="3">八上</el-radio>
        <el-radio v-model="blog.target" label="4">八下</el-radio>
        
    </div>
    <el-input class="info" v-model="blog.videoUrl" placeholder="视频链接（没有不填）"></el-input>

        
    <div class="info"><el-checkbox  v-model="blog.isForm">有表格</el-checkbox> </div>
        
        <!--     <div id="demo1"></div> -->
        <!-- <el-button type="primary" @click="getEditorData">清空输入</el-button> -->
        <!-- v-model="blog.content" -->
        <!-- 文本编辑器 -->
        <div id="main">
            <!-- <mavon-editor ref='md' @imgAdd="$imgAdd" @change="getValue()" v-model="blog.content" /> -->
            <mavon-editor ref=md @imgAdd="$imgAdd" @imgDel="$imgDel" v-model="blog.content" @change="getValue"></mavon-editor>
        </div>
        <div class="form">
            <el-button type="primary" v-show="!editState" @click="addblog(blog)">添加文章</el-button>
            <el-button v-show="editState" type="primary" @click="modifyBlog">修改文章</el-button>
            <el-button type="primary" @click="clearInput">清空输入</el-button>
        </div>


    </div>
</template>
    
<script>
import {
    getStore
} from "@/utils/storage";
import url from "@/serviceAPI.config.js";
import axios from "axios"
import mavonEditor from 'mavon-editor'// 新增：导入样式
import 'mavon-editor/dist/css/index.css'
export default {
    components: { 'mavonEditor': mavonEditor.mavonEditor },
    data() {
        return {
            value: '',
            editState: false,
            blog: {
                title: '',
                target: '',
                content: '',
                isForm: false,
                videoUrl: '',

                playLong: '',
                coverPic: '',
                html: '',
 
            },

        };
    },
    methods: {
        getContent() {
            // alert(this.editorContent)
            var json = this.editor.txt.getJSON() // 获取 JSON 格式的内容
            var jsonStr = JSON.stringify(json)
            console.log(json)
            console.log(jsonStr)
            console.log(this.editorContent, '----------------------')
        },
        clearInput() {
            this.blog = {}
        },

        addblog(blog) {
            console.log(blog)
            if(blog.type == true){
                blog.type = "video"
            }else{
                blog.type = "img"
            }


            axios({
                url: url.course,
                method: "post",
                data: {
                    data: blog
                },
            })
                .then((Response) => {
                    console.log(Response)
                    if (Response.data.code == 100) {
                        alert("插入文章成功");
                    } else {
                        alert("插入文章失败，状态码错误");
                    }
                })
                .catch((error) => {
                    alert("插入文章失败error", error);
                });
        },
        modifyBlog() {
            axios({
                url: url.course,
                method: "put",
                data: {
                    data: this.blog
                }
            })
                .then((Response) => {
                    console.log(Response)
                    if (Response.data.code == 100) {
                        this.$store.commit('blogState')
                        alert("修改文章成功");
                    } else {
                        alert("修改文章失败，状态码错误");
                    }
                })
                .catch((error) => {
                    alert("修改文章成功");
                    console.log(error)
                    // alert("修改文章失败error");
                });
        },
        $imgAdd(pos, $file) {
            console.log("img add");
            // 第一步.将图片上传到服务器.
            var formdata = new FormData();
            formdata.append('image', $file);
            // console.log("formdata", formdata);
            axios({
                url: url.upload,
                method: 'post',
                data: formdata,
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then((res) => {
                console.log("res", res);
                // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
                /**
                * $vm 指为mavonEditor实例，可以通过如下两种方式获取
                * 1. 通过引入对象获取: `import {mavonEditor} from ...` 等方式引入后，`$vm`为`mavonEditor`
                * 2. 通过$refs获取: html声明ref : `<mavon-editor ref=md ></mavon-editor>，`$vm`为 `this.$refs.md`
                */
                let url = res.data.url
                this.$refs.md.$img2Url(pos, url);
                // $vm.$img2Url(pos, url);
            })
        },
        getValue(value, html) {
            // this.blog.html = html
        },
        $imgDel(filenNme) {
            console.log(filenNme[0]);
            // axios({
            //     url: url.upload,
            //     method: "delete",
            //     data: {
            //         filenNme: filenNme,
            //     },
            // })
            //     .then((response) => {
            //         console.log(response);
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //         // this.$imgDel();
            //     });
        }

    },
    created() {
        this.editState = this.$store.state.courseState
        console.log(this.editState)
        if (this.editState) {
            this.blog = this.$route.query.id
        } else {
            this.blog = {}
            this.clearInput()
        }
    },


}
</script>
    
<style scoped>
div {
    width: 60vw;
}

.blogeditor {
    padding: 30px;
}

.info {
    display: flex;
    justify-content: flex-start;
    margin: 10px auto;
    width: 60vw;
}

.uploader {
    margin: 20px;
}
</style>
    